import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getCurrentUserId } from "../../auth";
import { useDispatch, useSelector } from "react-redux";
import { getEventsState, getIsUserAdmin } from "../../selectors";
import { icons } from "../../assets/icons";

import "../cms/page/LinkToEditPage.scss";
import EventsEditor from "./EventsEditor";
import { requestPreInitEvents } from "../../state/actions/events";
import { useLanguage } from "../../hooks";
import { getEnvVariable } from "../../.env.custom";
import { Loading } from "../Common";
import EventsDates from "./EventsDates";

const Events = ({ title }) => {
  const dispatch = useDispatch();
  const lng = useLanguage();
  const [showEditor, setShowEditor] = useState(false);
  const userId = getCurrentUserId();
  const isAdmin = useSelector(getIsUserAdmin(userId));
  const { isFetching, payload: events } = useSelector(getEventsState);

  useEffect(() => {
    const url = `${getEnvVariable("REACT_APP_API_ROOT")}events/?lng=${lng}`;
    dispatch(requestPreInitEvents(url));
  }, [lng, dispatch]);

  const openEditor = () => {
    setShowEditor(true);
  };

  const closeEditor = () => {
    setShowEditor(false);
  };

  const modalIsOpen = isAdmin && showEditor;

  return (
    <div className="event-wrapper">
      <EventsEditor isOpen={modalIsOpen} onClose={closeEditor} />
      {isAdmin && (
        <span className="editPageLink" onClick={openEditor}>
          <img alt="Edit events" src={icons.Edit} />
        </span>
      )}
      <h3 className="vf-text--center">{title}</h3>
      {isFetching && <Loading />}
      {!isFetching && events && <EventsDates events={events} />}
    </div>
  );
};

Events.propTypes = {
  title: PropTypes.string,
};

Events.defaultProps = {
  title: "",
};

export default Events;
