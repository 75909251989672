// @ts-check
import { put, call, takeEvery, select } from "redux-saga/effects";
import actionCreators from "../actions/events";
import { getFetchEventsIfNeeded } from "../../selectors";
import api, { apiDelete, apiPost, apiPut } from "../api";

function* fetchEvents({ url }) {
  try {
    const fetchNeeded = yield select(getFetchEventsIfNeeded);
    if (fetchNeeded) {
      const events = yield call(api, url);

      yield put(actionCreators.fetchSucceeded(events));
    }
  } catch (error) {
    console.log("events error", error);

    yield put(actionCreators.fetchFailed(error));
  }
}

function* createEvent({ url, payload }) {
  try {
    yield put(actionCreators.createEventInit());

    const response = yield call(api, url, apiPost(payload));

    if (response) {
      yield put(actionCreators.createEventSuccess(response));
      yield put(actionCreators.requestPreInitEvents(url));
    }
  } catch (err) {
    yield put(actionCreators.creatEventFailed(err));
    console.log("create event error", err);
  }
}

function* updateEvent({ url, payload, lang }) {
  try {
    yield put(actionCreators.updateEventInit());

    const response = yield call(
      api,
      `${url}/${payload.id}?lng=${lang}`,
      apiPut(payload)
    );

    if (response) {
      yield put(actionCreators.updateEventSuccess(response));
      yield put(actionCreators.requestPreInitEvents(`${url}?lng=${lang}`));
    }
  } catch (err) {
    yield put(actionCreators.updateEventFailed(err));
    console.log("update event error", err);
  }
}

function* removeEvent({ url, eventId, lang }) {
  try {
    yield put(actionCreators.removeEventInit());

    const response = yield call(api, `${url}/${eventId}`, apiDelete());

    if (response) {
      yield put(actionCreators.removeEventSuccess(response));
      yield put(actionCreators.requestPreInitEvents(`${url}?lng=${lang}`));
    }
  } catch (err) {
    yield put(actionCreators.removeEventFailed(err));
    console.log("remove event error", err);
  }
}

export default function* eventsSaga() {
  yield takeEvery("EVENTS/FETCH_PRE_INIT", fetchEvents);
  yield takeEvery("EVENT/CREATE_EVENT_PRE_INIT", createEvent);
  yield takeEvery("EVENT/UPDATE_EVENT_PRE_INIT", updateEvent);
  yield takeEvery("EVENT/REMOVE_EVENT_PRE_INIT", removeEvent);
}
