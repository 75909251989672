// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { withFormik, Field } from 'formik'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import actions from '../../state/actions/users'
import { Checkbox, SubmitButton } from '../Common'
import { VfButton, VfLinkButton } from '../DesignSystem/Buttons'
import { getCurrentUserId } from '../../auth'

const EditForm = ({
  t,
  values,
  handleSubmit,
  isSubmitting,
  dirty,
  message
}) => {
  const subscriptionLabels = Object.keys(values.subscriptions)
  const notificationLabels = Object.keys(values.notifications)

  return (
    <form onSubmit={handleSubmit}>
      <div className='vf-row'>
        <fieldset className='vf-col-lg-6'>
          {/* Subscriptions */}
          <legend className='mb-2'>{t('forms.editUser.legend1')}</legend>

          {subscriptionLabels.map(label => (
            <Field
              key={label}
              name={`subscriptions.${label}`}
              label={label}
              component={Checkbox}
            />
          ))}
        </fieldset>

        <fieldset className='vf-col-lg-6'>
          {/* Notifications */}
          <legend className='mb-2'>{t('forms.editUser.legend2')}</legend>

          {notificationLabels.map(label => (
            <Field
              key={label}
              name={`notifications.${label}`}
              label={t(`forms.editUser.group2.${label}`)}
              component={Checkbox}
            />
          ))}
        </fieldset>
      </div>

      <div className='vf-buttons-container mt-2'>
        <VfLinkButton
          text={t('common.cancel')}
          to='/user'
          className='mr-2 mt-2'
          color='secondary'
          size='large'
          disabled={isSubmitting}
        />

        <SubmitButton
          button={
            <VfButton text={t('common.submit')} size='large' className='mt-2' />
          }
          inProgress={isSubmitting}
          inProgressText={t('common.submitting')}
          disabled={!dirty || isSubmitting}
        />
      </div>

      {message && !dirty && (
        <p className='vf-utility-typo--success mt-3'>
          {t('messages.success')}
          {'.'} {t('messages.redirect')}{' '}
          <Link to='/user'>{t('common.profilePage')}</Link>.
        </p>
      )}
    </form>
  )
}

EditForm.propTypes = {
  t: PropTypes.func,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  message: PropTypes.bool
}

const validationSchema = yup.lazy(values => {
  const getShape = vals =>
    Object.keys(vals).reduce(
      (acc, next) => ({ ...acc, [next]: yup.boolean() }),
      {}
    )

  return yup.object().shape({
    subscriptions: yup.object().shape(getShape(values.subscriptions)),
    notifications: yup.object().shape(getShape(values.notifications))
  })
})

const formikConfig = {
  mapPropsToValues: ({ user }) => {
    const subscriptions = user.subscriptionsConfiguration.reduce(
      (acc, next) => ({
        ...acc,
        [next.challangeTitle]: next.activeSubscription
      }),
      {}
    )

    return {
      subscriptions,
      notifications: user.notificationsConfiguration
    }
  },

  validationSchema,

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const { updateSettingsInit, notify, user } = props

    const userUrl = user._links.self.href
    const notificationsUrl = user._links.updateNotifications.href
    const subscriptionsUrl = user._links.updateSubscriptions.href

    const subscriptions = user.subscriptionsConfiguration
      .filter(sub => values.subscriptions[sub.challangeTitle])
      .map(sub => sub.challangeId)

    const userId = getCurrentUserId()

    // update settings
    updateSettingsInit(
      [notificationsUrl, subscriptionsUrl],
      [values.notifications, subscriptions],
      userUrl,
      userId,
      () => {
        resetForm()
        setSubmitting(false)
        notify()
      }
    )
  },

  displayName: 'UserSettingsForm'
}

export default connect(null, {
  updateSettingsInit: actions.updateSettingsInit
})(withFormik(formikConfig)(EditForm))
