// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

import './hero.scss'
import logo from '../../../assets/hero_logo.png'

const VfHero = ({
  imageUrl,
  headline,
  videoFrame,
  videoSrcSet,
  text,
  showVideo,
}) => {

  let style = {
    backgroundImage: `url(${imageUrl})`,
    position: 'relative',
  }

  return (
    <section
      className={`vf-hero hero ${showVideo || 'no-video'} ${videoFrame && 'video-embedded'}`}
      style={style}>
      <img src={logo} className="hero__logo" alt="Logo" />
      {showVideo && !videoFrame && Array.isArray(videoSrcSet) && !!videoSrcSet.length && (
        <div className="hero__video-container">
          <video
            autoPlay
            muted
            loop
            controls
            controlsList="nodownload nofullscreen">
            {videoSrcSet.map((item, idx) => (
              <source key={idx} src={item.src} type={item.type} />
            ))}
          </video>
        </div>
      )}

      {videoFrame && (
        <div
          className="hero__video-embedded-wrapper"
          dangerouslySetInnerHTML={{ __html: videoFrame }}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      )}

      {(headline || text) && (
        <div className="vf-hero__content">
          <h1 className="vf-hero__headline hero__title">{headline}</h1>
          {text}
        </div>
      )}
    </section>
  )
}

VfHero.propTypes = {
  imageUrl: PropTypes.string,
  videoFrame: PropTypes.string,
  headline: PropTypes.string,
  text: PropTypes.string,
  showVideo: PropTypes.bool,
  videoSrcSet: PropTypes.array,
}

VfHero.defaultProps = {
  headline: '',
  text: '',
  showVideo: false,
  videoSrcSet: null,
  videoFrame: null,
}

export default VfHero
