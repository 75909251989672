import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { VfContainer } from '../../Components/DesignSystem/Utils'
import { WithNotification } from '../../Components/cms/challenge/Notifications'
import { useLanguage } from '../../hooks'
import { getInfoBoxesState } from '../../selectors'
import EditInfoBoxForm from '../../Components/cms/page/EditInfoBoxForm'
import { useDispatch, useSelector } from 'react-redux'
import { requestPreInitInfoBoxes } from '../../state/actions/infoboxes'
import { getEnvVariable } from '../../.env.custom'

const EditOverviewBox = ({ computedMatch }) => {
  const { overviewBoxId } = computedMatch.params
  const dispatch = useDispatch()
  const lng = useLanguage()
  const { payload } = useSelector(getInfoBoxesState)

  const boxes = payload?.boxes

  useEffect(() => {
    const url = `${getEnvVariable('REACT_APP_API_ROOT')}info-boxes/overview/?lng=${lng}`
    dispatch(requestPreInitInfoBoxes(url))
  }, [dispatch, lng])

  const matchingBox = Array.isArray(boxes) && boxes.find(box => box.id === overviewBoxId)

  const FormWithNotifications = (
    <WithNotification Component={EditInfoBoxForm} item={matchingBox} />
  )
  return (
    <VfContainer>
      <h1 className="my-4">Edit Info Box</h1>
      {FormWithNotifications}
    </VfContainer>
  )
}

EditOverviewBox.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object,
}

export default EditOverviewBox
