// @ts-check

import React, { useState, useEffect } from 'react'
import { getToken, login } from '../auth'

const withAuthentication = Component => props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // console.log('isAuthenticated', isAuthenticated)

  useEffect(() => {
    getToken().then(token => {
      // console.log('in', token)

      setIsAuthenticated(true) // success
    },
    login // error
    )
  }, [])

  return isAuthenticated ? <Component {...props} /> : null
}

export default withAuthentication
