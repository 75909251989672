import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import callApi from '../state/api'
import cmsHero from './cmsBlocks/cmsHero'
import cmsImageBlock from './cmsBlocks/cmsImageBlock'
import cmsImagesSection from './cmsBlocks/cmsImagesSection'
import cmsTitle from './cmsBlocks/cmsTitle'
import { ScrollableTimeline } from '../Components/Common'
import { getEnvVariable } from '../.env.custom'
import { YEARS_DATA } from './hallOfFameData'

// WIP, to be moved to global map
const cmsBlocks = {
  'blocks.hero': cmsHero,
  'blocks.title': cmsTitle,
  'blocks.images': cmsImagesSection,
  'blocks.image-block': cmsImageBlock,
}

const block = block => {
  // component does exist
  if (typeof cmsBlocks[block.__component] !== 'undefined') {
    return React.createElement(cmsBlocks[block.__component], {
      key: block.id,
      block,
    })
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    {
      key: block.id,
    },
  )
}

function HallOfFamePage({
  description,
  draftId,
  ...props
}) {
  const [page, setPage] = useState({})
  const url = getEnvVariable('REACT_APP_STATIC_HF')

  useEffect(() => {
    callApi(url).then(
      content => {
        setPage(content);
      }
    )
  }, [])

  const firstBlock = page?.contentBlocks?.[0] || null;
  const remainingBlocks = page?.contentBlocks?.slice(1) || [];

  return page.contentBlocks ? (
    <>
      <div className="vf-container">
        {block(firstBlock)}
      </div>
      <ScrollableTimeline years={YEARS_DATA} />
      <div className="vf-container">
        {remainingBlocks.map(block)}
      </div>
    </>
  ) : (
    <div className="vf-container">loading...</div>
  )
}

HallOfFamePage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string,
}

export default HallOfFamePage
