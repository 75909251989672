import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getEnvVariable } from '../../.env.custom'
import { useSelector } from 'react-redux'
import { getPeopleGroupsState } from '../../selectors'

import './Contacts.scss'

const ContactPerson = React.memo(({
  email,
  id,
  image,
  jobPosition,
  name,
}) => {
  const basePath = image ? `${getEnvVariable('REACT_APP_API_ROOT')}images/content?imageId=` : null
  const imageUrl = image ? `${basePath}${image.id}` : null

  return (
    <div key={id} className="contact-person">
      {image ? (
        <div className="contact-details__image-wrapper">
          <img src={imageUrl} alt={name} />
        </div>
      ) : (
        <div className="contact-details__image-wrapper no-image" />
      )}
      <span className="contact-details__name">{name}</span>
      <span className="contact-details__job">{jobPosition}</span>
      <a className="contact-details__email" href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  )
})

ContactPerson.propTypes = {
  email: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image: PropTypes.object,
  jobPosition: PropTypes.string,
  name: PropTypes.string.isRequired,
}

const ContactPeople = ({ contacts }) => {
  const contactGroups = useSelector(getPeopleGroupsState)

  const groupedContacts = useMemo(() => {
    if (!Array.isArray(contactGroups)) return []
    return contactGroups.map(group => ({
      groupName: group?.name,
      groupDescription: group?.description,
      contacts: contacts?.filter(contact => contact?.peopleGroupId === group?.id) || [],
    }))
  }, [contactGroups, contacts])

  return (
    <div>
      {groupedContacts.map(({
        groupName,
        groupDescription,
        contacts: groupContacts,
      }, index) => (
        <div key={`${groupName}-${index}`} className="contact-group">
          <h4 className="contact-group-title">{groupName}</h4>
          <p className="contact-group-description">{groupDescription}</p>
          <div className="contact-people">
            {groupContacts.map(contact => (
              <ContactPerson key={contact.id} {...contact} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

ContactPeople.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    image: PropTypes.object,
    jobPosition: PropTypes.string,
    name: PropTypes.string.isRequired,
    peopleGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
}

ContactPeople.defaultProps = {
  contacts: [],
}

export default ContactPeople
