// @ts-check
import React, { useEffect, useState, Suspense } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import Template from "./Components/Template";
import ScrollToTop from "./Components/ScrollToTop";
import { requestPreInit as requestPreInitConfig } from "./state/actions/config";
import actions from "./state/actions/data";
import { withAuthentication, useProfile, useData, useLanguage } from "./hooks";
import { getPageUrl, getPageDetails } from "./selectors";
import { dataTypes } from "./configurations";
import TelemetryProvider from "./appInsights/telemetry-provider";
import NotificationCountContext from "./Components/Template/NotificationCountContext";
import { ReleaseIntro } from "./pages";

const RouteWithTemplate = ({
  component: Component,
  template,
  pageId,
  ...rest
}) => {
  const lng = useLanguage();
  const { data } = useData(
    dataTypes.pageDetails.name,
    { lng },
    getPageUrl(pageId),
    getPageDetails,
    actions.requestPreInit
  );

  const InnerTemplate =
    template && template.component ? template.component : null;

  const heroImg = !!data ? data._links.heroImage?.href : null; // wp: cannot be : '' in here because of console Warning
  const title = !!data ? data.title : null; // wp: cannot be : '' in here because of console Warning

  return (
    <Route
      {...rest}
      render={(props) => (
        <Template {...rest}>
          {InnerTemplate ? (
            <InnerTemplate heroImg={heroImg} title={title}>
              <Component {...rest} {...data} />
            </InnerTemplate>
          ) : (
            <Component {...rest} {...data} />
          )}
        </Template>
      )}
    />
  );
};

RouteWithTemplate.propTypes = {
  component: PropTypes.func,
  template: PropTypes.object,
  pageId: PropTypes.string,
};

RouteWithTemplate.defaultProps = {
  template: null,
};

const AuthenticatedRouteWithTemplate = withAuthentication(RouteWithTemplate);
const AuthenticatedIntroPage = withAuthentication(ReleaseIntro);

function App() {
  const dispatch = useDispatch();
  const [notificationsCounter, setNotificationsCounter] = useState(0);
  const { user } = useProfile();
  const [hideIntro, setHideIntro] = useState(false);

  useEffect(() => {
    dispatch(requestPreInitConfig());
  }, [dispatch]);

  useEffect(() => {
    if (!!user) {
      setNotificationsCounter(user.pendingNotificationsCount);
    }
  }, [user]);

  const resetCounter = () => setNotificationsCounter(0);

  const releaseDate = new Date("2024-03-19");

  const isBeforeRelease = new Date() < releaseDate;
  const displayIntro = isBeforeRelease && !hideIntro;

  return (
    <Suspense fallback={null}>
      <Router>
        <TelemetryProvider>
          <ScrollToTop>
            <NotificationCountContext.Provider
              value={{ resetCounter, counter: notificationsCounter }}
            >
              <div>
                <Switch>
                  {displayIntro && (
                    <Route
                      render={() => (
                        <AuthenticatedIntroPage
                          onHide={() => setHideIntro(true)}
                        />
                      )}
                    />
                  )}
                  {routes.map((item, i) => (
                    // @ts-ignore
                    <AuthenticatedRouteWithTemplate key={i} {...item} />
                  ))}
                </Switch>
              </div>
            </NotificationCountContext.Provider>
          </ScrollToTop>
        </TelemetryProvider>
      </Router>
    </Suspense>
  );
}

export default App;
