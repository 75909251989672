import striptags from "striptags";
import he from "he";

export const truncateTextAtWord = (text, maxChars) => {
  if (text.length <= maxChars) return text;
  let end = text.lastIndexOf(" ", maxChars);
  if (end === -1) end = Math.min(text.length, maxChars);
  return text.substring(0, end) + "...";
};

export const stripPTags = (inputString) => {
  return inputString.replace(/<\/?p>/g, "");
};

export const formatDate = (isoDate) => {
  if (!isoDate) return "";

  return new Date(isoDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const removeHtmlTagsAndEntities = (str) => {
  const withoutTags = striptags(str);
  const decoded = he.decode(withoutTags);
  return decoded;
};
