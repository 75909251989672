import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getIsUserAdmin } from '../../../selectors'
import { getCurrentUserId } from '../../../auth'
import { icons } from '../../../assets/icons'

import './LinkToEditPage.scss'

const LinkToEditOverviewPage = ({ pageId }) => {
  const userId = getCurrentUserId()
  const isAdmin = useSelector(getIsUserAdmin(userId))
  const currentUrl = window.location.pathname
  return isAdmin ? (
    <Link
      className='editPageLink'
      to={`/cms/overviewBox/${pageId}/update?returnTo=${currentUrl}`}
      title='Edit this box'>
      <img alt='' src={icons.Edit} />
    </Link>
  ) : null
}

LinkToEditOverviewPage.propTypes = {
  pageId: PropTypes.string
}

export default LinkToEditOverviewPage
