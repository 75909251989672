import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import userIcon from '../../assets/icons/VF_user_rgb.svg'
import { getCurrentUserId } from '../../auth'
import { getUserDetails } from '../../selectors'

const sizes = {
  small: 32,
  medium: 64,
  large: 120
}

const Avatar = ({ author, size, alt }) => {
  const [imageSrc, setImageSrc] = useState(userIcon)
  const photoUrl = author ? author._links.photo.href : ''

  useEffect(() => {
    if (!!photoUrl) {
      setImageSrc(photoUrl)
    }
  }, [photoUrl])

  return <img
    src={imageSrc}
    alt={alt}
    style={{ width: sizes[size], borderRadius: '50%' }}
    onError={() => setImageSrc(userIcon)}
  />
}

Avatar.propTypes = {
  size: PropTypes.string,
  alt: PropTypes.string,
  author: PropTypes.object
}

Avatar.defaultProps = {
  size: 'small',
  alt: 'User Avatar',
  photoUrl: ''
}

export const AvatarMedium = (props) => <Avatar size='medium' {...props} />
export const AvatarLarge = (props) => <Avatar size='large' {...props} />
export const AvatarCurrentUser = () => {
  const author = useSelector(getUserDetails(getCurrentUserId()))
  return <Avatar size='small' author={author} />
}

export default Avatar
