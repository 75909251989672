import React from 'react'
import PropTypes from 'prop-types'
import { VfContainer } from '../../Components/DesignSystem/Utils'
import { EditForm } from '../../Components/cms/page'
import { WithNotification } from '../../Components/cms/challenge/Notifications'

const EditPage = ({ computedMatch }) => {
  const { pageId } = computedMatch.params
  const FormWithNotifications = (
    <WithNotification Component={EditForm} pageId={pageId} />
  )
  return (
    <VfContainer>
      <h1 className='my-4'>Edit page</h1>
      {FormWithNotifications}
    </VfContainer>
  )
}

EditPage.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object
}

export default EditPage
