import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { useVfAlchemyScript, useLanguage, useDropDownFix } from '../../../hooks'
import { DropDown, SubmitButton } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'

import { scoreRequestPreInit } from '../../../state/actions/other'
import { getCurrentUserId } from '../../../auth'

import { mergeUrlWithParams } from '../../../helpers/url'

const DropDownItem = ({ item: { id, label } }) => (
  <div className='vf-col-sm-12 vf-col-md-6'>
    <Field
      name={id}
      label={`${label} *`}
      component={DropDown}
      options={[
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 }
      ]}
    />
  </div>
)

DropDownItem.propTypes = {
  item: PropTypes.object
}

const RatingForm = ({
  t,
  categories,
  initialValues,
  validationSchema,
  handleSubmit,
  handleCancel
}) => {
  useVfAlchemyScript()
  useDropDownFix('#rating-form')

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting, dirty }) => (
        <Form>
          <div className='vf-row' id='rating-form'>
            {Object.keys(categories)
              .map(key => categories[key])
              .map(item => (
                <DropDownItem key={item.id} item={item} />
              ))}
          </div>

          {/* <div className='mt-2 vf-row'>
            <div className='vf-col'>
              <label htmlFor='coment'>Expert comments</label>
              <Field name='comment' component='textarea' rows={4} />
            </div>
          </div> */}

          <div className='vf-row mt-3'>
            <SubmitButton
              button={
                <VfButton className='m-1' text={t('common.saveChanges')} />
              }
              inProgress={isSubmitting}
              disabled={!dirty || isSubmitting}
            />
            <VfButton
              text={t('common.cancel')}
              color='secondary'
              className='m-1'
              disabled={isSubmitting}
              onClick={handleCancel}
            />
          </div>
        </Form>
      )}
    />
  )
}

RatingForm.propTypes = {
  t: PropTypes.func,
  categories: PropTypes.object,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func
}

const RatingFormContainer = ({ idea, categories, ...other }) => {
  const { t } = useTranslation()
  const currentUserId = getCurrentUserId()
  const currentUserScores = idea.scores.find(
    item => item.expert.id === currentUserId
  )
  const lng = useLanguage()

  const emptyScores = Object.keys(categories)
    .map(item => ({ [item]: 0 }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  const ratingsInitialValues = !!currentUserScores
    ? currentUserScores.rates
    : emptyScores
  const initialValues = { ...ratingsInitialValues, comment: '' }

  const ratingsShape = Object.keys(categories).reduce(
    (acc, category) => ({
      ...acc,
      [category]: yup
        .number()
        .oneOf([1, 2, 3, 4, 5], 'required')
        .required('required')
    }),
    {}
  )

  const validationSchema = yup.object().shape(ratingsShape)

  const dispatch = useDispatch()

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const url = idea._links.score.href
    const refreshUrlRaw = idea._links.self.href

    const refreshUrl = mergeUrlWithParams({ lng })(refreshUrlRaw)
    const callback = () => {
      setSubmitting(false)
      resetForm()
    }
    dispatch(scoreRequestPreInit(url, refreshUrl, idea.id, values, callback))
  }

  return (
    <RatingForm
      t={t}
      categories={categories}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      {...other}
    />
  )
}

RatingFormContainer.propTypes = {
  idea: PropTypes.object,
  categories: PropTypes.object
}

export default RatingFormContainer
