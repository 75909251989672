// @ts-check

import React, { useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import { getAuthorFullName } from '../../../helpers/author'

const RadarChart = ({ labels, dataSets: datasets }) => {
  const ctxRef = createRef()

  useEffect(() => {
    const ctx = ctxRef.current.getContext('2d')

    const options = {
      scale: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          max: 5
        }
      },
      legend: {
        position: 'bottom'
      },
      animation: {
        duration: 0
      }
    }

    const radar = new Chart(ctx, {
      type: 'radar',
      data: {
        labels,
        datasets
      },
      options
    })

    radar.update()
  }, [ctxRef, datasets, labels])

  return (
    <div className='mt-3'>
      <canvas ref={ctxRef} />
    </div>
  )
}

RadarChart.propTypes = {
  labels: PropTypes.array,
  dataSets: PropTypes.array
}

const bgColors = [
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 99, 132, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(255, 159, 64, 0.2)'
]

const categoryValue = categories => rates =>
  Object.keys(categories).map(item => rates[item])

const formatDataForChart = (rows, avgRates, categories) => {
  const result = []
  const getValues = categoryValue(categories)

  rows.forEach((row, idx) =>
    result.push({
      data: getValues(row.rates),
      label: getAuthorFullName(row.expert),
      backgroundColor: bgColors[idx]
    })
  )
  // all experts average
  result.push({
    data: getValues(avgRates),
    label: 'total',
    backgroundColor: 'transparent',
    borderColor: '#222'
  })

  return result
}

const RadarChartContainer = ({ idea, categories }) => {
  const labels = Object.keys(categories)
    .map(item => categories[item])
    .map(item => item.label)
  const { scores, avgScore } = idea
  const dataSets = formatDataForChart(scores, avgScore, categories)

  return <RadarChart labels={labels} dataSets={dataSets} />
}

RadarChartContainer.propTypes = {
  idea: PropTypes.object,
  categories: PropTypes.object
}

export default RadarChartContainer
