import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getCurrentUserId } from '../../auth'
import { getContactsState, getIsUserAdmin } from '../../selectors'
import { icons } from '../../assets/icons'

import '../cms/page/LinkToEditPage.scss'
import ContactsEditor from './ContactsEditor'
import { requestPreInitContacts } from '../../state/actions/contacts'
import { useLanguage } from '../../hooks'
import { getEnvVariable } from '../../.env.custom'
import { Loading } from '../Common'
import ContactPeople from './ContactPeople'
import { requestPreInitPeopleGroups } from '../../state/actions/peopleGroups'

const Contacts = ({ title }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()
  const [showEditor, setShowEditor] = useState(false)
  const userId = getCurrentUserId()
  const isAdmin = useSelector(getIsUserAdmin(userId))
  const {
    isFetching,
    payload: contacts,
  } = useSelector(getContactsState)

  useEffect(() => {
    const contactsUrl = `${getEnvVariable('REACT_APP_API_ROOT')}people/?lng=${lng}`
    dispatch(requestPreInitContacts(contactsUrl))
  }, [lng, dispatch])

  useEffect(() => {
    const groupsUrl = `${getEnvVariable('REACT_APP_API_ROOT')}people-groups/?lng=${lng}`
    dispatch(requestPreInitPeopleGroups(groupsUrl))
  }, [lng, dispatch])

  const openEditor = () => setShowEditor(true)

  const closeEditor = () => setShowEditor(false)

  const modalIsOpen = isAdmin && showEditor

  return (
    <div className="contact-wrapper">
      <ContactsEditor isOpen={modalIsOpen} onClose={closeEditor} />
      {isAdmin && (
        <span className="editPageLink" onClick={openEditor}>
          <img alt="Edit contacts" src={icons.Edit} />
        </span>
      )}
      <h3 className="vf-text--center">
        {title}
      </h3>
      {isFetching ? <Loading /> : contacts?.length > 0 && <ContactPeople contacts={contacts} />}
    </div>
  )
}

Contacts.propTypes = {
  title: PropTypes.string,
}

Contacts.defaultProps = {
  title: '',
}

export default Contacts
