import React, { createRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getToken } from '../auth'
import { VfButton } from './DesignSystem/Buttons'
import { getUrlFromConfig } from '../selectors'

const statuses = {
  ready: 'ready',
  fetching: 'fetching'
}

const Export = (props) => {
  const [status, setStatus] = useState(statuses.ready)

  const url = useSelector(getUrlFromConfig('export-challenges'))
  const downloadBtnRef = createRef()

  async function getData () {
    const token = await getToken()
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token.idToken.rawIdToken}`
      }
    })
    return response.arrayBuffer()
  }

  async function handleExportClick () {
    const thisRef = { ...downloadBtnRef }

    setStatus(statuses.fetching)
    const buffer = await getData()
    const blob = new Blob([buffer],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const objectUrl = URL.createObjectURL(blob)
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob)
    } else {
      thisRef.current.href = objectUrl
      thisRef.current.click()
    }
    setStatus(statuses.ready)
  }

  const text = status === statuses.ready ? 'Export Challenges' : 'working...'
  const disabled = status !== statuses.ready

  return <>
    <a style={{display: 'none'}} ref={downloadBtnRef} href='#' download='challenges.xlsx' >download</a> {/* eslint-disable-line */}
    <VfButton text={text} onClick={handleExportClick} disabled={disabled} {...props} />
  </>
}

export default Export
