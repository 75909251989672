import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '../DesignSystem/Modal'
import { VfButton } from '../DesignSystem/Buttons'
import { Loading } from '../Common'
import { getContactsState, getPeopleGroupsState } from '../../selectors'
import { useLanguage, useVfAlchemyScript } from '../../hooks'

import { getEnvVariable } from '../../.env.custom'
import { requestPreInitContacts } from '../../state/actions/contacts'
import { requestPreInitPeopleGroups } from '../../state/actions/peopleGroups'
import ContactForm from './ContactForm'
import PeopleGroupsForm from './PeopleGroupsForm'

const ContactsEditor = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const lng = useLanguage()
  const [lang, setLang] = useState(lng)
  const { t } = useTranslation()
  const {
    isFetching,
    payload: contacts,
  } = useSelector(getContactsState)

  useVfAlchemyScript()

  useEffect(() => {
    if (isOpen) {
      const contactsUrl = `${getEnvVariable('REACT_APP_API_ROOT')}people/?lng=${lng}`
      dispatch(requestPreInitContacts(contactsUrl))

      const groupsUrl = `${getEnvVariable('REACT_APP_API_ROOT')}people-groups/?lng=${lng}`
      dispatch(requestPreInitPeopleGroups(groupsUrl))
    }
  }, [isOpen, lng, dispatch])

  const existingContacts = useMemo(() => (
    (contacts || [])
      .filter(item => item.id !== null)
      .map(item => (
        <ContactForm
          contact={item}
          isEditMode
          onClose={onClose}
          key={item.id}
          lang={lang}
        />
      ))
  ), [contacts, onClose, lang])

  return (
    <Modal hidden={!isOpen} closeModal={onClose} fullScreen>
      <div className="contacts-editor-content">
        <h4>Contacts editor</h4>
        <ContactForm onClose={onClose} lang={lang} setLang={setLang} />
        <p className="mt-2 mb-1">Created contacts</p>
        {isFetching ? <Loading /> : contacts && contacts.length > 0 ? existingContacts : <p>No contacts found.</p>}
        <h4 className="mt-4">Groups editor</h4>
        <PeopleGroupsForm lang={lang} />
        <div className="vf-row my-3">
          <VfButton
            text={t('common.cancel')}
            color="secondary"
            onClick={onClose}
            className="vf-col m-1"
          />
        </div>
      </div>
    </Modal>
  )
}

ContactsEditor.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

ContactsEditor.defaultProps = {
  isOpen: false,
  onClose: () => {
  },
}

export default ContactsEditor
