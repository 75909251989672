// @ts-check

import { getCurrentUserId } from '../auth'
/* #region Config */

export const getFetchConfigIfNeeded = ({ config }) => !config.payload.language

export const getUrlFromConfig =
  (key) =>
    ({ config }) => {
      try {
        // console.info('getUrlFromConfig says: Got the url for', key)
        return config.payload._links[key].href
      } catch {
        // console.warn('getUrlFromConfig says: Cannot get url for', key)
        return ''
      }
    }

/* #endregion */

/* #region User */

export const getUserUrl = getUrlFromConfig('profile')

export const getFetchUserIfNeeded =
  (id) =>
    ({ users }) => {
      if (users.isFetching.includes(id)) {
        return false
      }

      return !users.all.includes(id) || users.invalidated.includes(id)
    }

export const getIsUserFetching = ({ users }) => users.isFetching

export const getUserDetails =
  (id = getCurrentUserId()) =>
    ({ users }) =>
      users.all.includes(id) ? users.payload[id] : null

const getUserById = ({ users }, id) => {
  try {
    return users.payload[id]
  } catch (ex) {
    return null
  }
}

export const getIsCurrentUser = (id) => getCurrentUserId() === id
export const getIsUserJuryMember = (id) => (state) => {
  const user = getUserById(state, id)
  return Boolean(user?.juryMember)
}
export const getIsUserAdmin = (id) => (state) => {
  const user = getUserById(state, id)
  return Boolean(user?.administrator)
}

const settingsTypes = {
  notifications: 'notifications',
  subscriptions: 'subscriptions',
}

const getSettingsUrl =
  (type) =>
    ({ users }) => {
      const links =
        users.payload[getCurrentUserId()] &&
        users.payload[getCurrentUserId()]._links

      if (links) {
        if (type === settingsTypes.notifications) {
          return links.updateNotifications.href
        } else if (type === settingsTypes.subscriptions) {
          return links.updateSubscriptions.href
        }
      }
      return ''
    }

export const getNotificationsUrl = getSettingsUrl(settingsTypes.notifications)
export const getSubscriptionsUrl = getSettingsUrl(settingsTypes.subscriptions)

export const getUserSubscriptionsIds = ({ users }) => {
  const currentUserId = getCurrentUserId()

  return users.payload[currentUserId]
    ? users.payload[currentUserId].subscriptionsConfiguration
      .filter((sub) => sub.activeSubscription)
      .map((sub) => sub.challangeId)
    : []
}

export const getUserPhoto =
  (userId) =>
    ({ users }) => {
      try {
        return users.payload[userId]._links.photo.href
      } catch {
        return ''
      }
    }

/* #endregion */

/* #region Filterables */
const getFilterablesListByKey =
  (type) =>
    (key) =>
      ({ dataCommon }) => {
        const ids = dataCommon[type].filters[key] || []
        const items = dataCommon[type].items ? dataCommon[type].items : {}

        return ids.map((id) => items[id])
      }

const fetchFilterablesIfNeeded =
  (type) =>
    (key) =>
      ({ dataCommon }) => {
        const root = dataCommon[type]
        if (root.isFetching.includes(key)) {
          return false
        }

        return !root.all.includes(key)
      }

export const getIsFetching =
  (type) =>
    (key) =>
      ({ dataCommon }) =>
        dataCommon[type].isFetching.includes(key)
/* #endregion */

/* #region Detailed */
const getItemDetailsById =
  (key) =>
    (id) =>
      ({ data }) => {
        try {
          return data[key].payload[id]
        } catch {
          return null
        }
      }

const fetchItemIfNeeded =
  (key) =>
    (item) =>
      ({ data }) => {
        return !data[key].all.includes(item)
      }
/* #endregion */

/* #region Activities */

export const getActivityTypes = ({ config }) => {
  return config.payload.activityTypes
    ? config.payload.activityTypes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.toLowerCase()]: curr.toLowerCase(),
      }),
      {},
    )
    : null
}

export const getActivityListUrl = getUrlFromConfig('activities')

export const fetchActivitiesIfNeeded = fetchFilterablesIfNeeded('activities')
export const getActivitiesList = getFilterablesListByKey('activities')
export const getIsActivityFetching = getIsFetching('activities')

export const getPagePrevUrl =
  (dataType) =>
    (key) =>
      ({ dataCommon }) => {
        try {
          return dataCommon[dataType].payload[key]._links.prev.href
        } catch {
          return ''
        }
      }
export const getPageNextUrl =
  (dataType) =>
    (key) =>
      ({ dataCommon }) => {
        try {
          return dataCommon[dataType].payload[key]._links.next.href
        } catch {
          return ''
        }
      }

const getPaginationSetup =
  (dataType) =>
    (key) =>
      ({ dataCommon }) => {
        const payload = dataCommon[dataType].payload[key]
        if (payload) {
          const {
            pageSize,
            currentPage,
            totalPages,
          } = payload
          return {
            pageSize,
            currentPage,
            totalPages,
          }
        }
        return {}
      }

export const getActivitiesPaginationSetup = getPaginationSetup('activities')
export const getActivitiesPagePrevUrl = getPagePrevUrl('activities')
export const getActivitiesPageNextUrl = getPageNextUrl('activities')

export const getIdeasPaginationSetup = getPaginationSetup('ideas')
export const getIdeasPagePrevUrl = getPagePrevUrl('ideas')
export const getIdeasPageNextUrl = getPageNextUrl('ideas')
export const getIdeasAdditionalInfo =
  (key) =>
    ({ dataCommon }) => {
      const payload = dataCommon.ideas.payload[key]
      if (payload) {
        const {
          totalElements,
          extendedProperties: {
            totalComments,
            totalLikes,
          },
        } = payload
        return {
          totalElements,
          totalComments,
          totalLikes,
        }
      }
    }

/* #endregion */

/* #region Challenges */
export const getChallengesListUrl = ({ config }) =>
  (config.payload._links && config.payload._links.challengesList.href) || ''

export const fetchChallengesListIfNeeded =
  fetchFilterablesIfNeeded('challenges')
export const getChallengesList = getFilterablesListByKey('challenges')
export const getIsChallengesFetching = getIsFetching('challenges')
export const getCreateChallengeUrl = getUrlFromConfig('challengeCreate-POST')
/* #endregion */

/* #region Challege Details */

/**
 * @param {object} config
 */
export const getChallengeDetailsUrl = getUrlFromConfig('challengeDetails')
export const getChallengeDetailsById = getItemDetailsById('challenge-details')
export const fetchChallengeDetailsIfNeeded =
  fetchItemIfNeeded('challenge-details')

/* #endregion */

/* #region Ideas */

export const getIdeasListUrl = getUrlFromConfig('ideasList')
export const fetchIdeasListIfNeeded = fetchFilterablesIfNeeded('ideas')
export const getIdeasList = getFilterablesListByKey('ideas')
export const getIsIdeasFetching = getIsFetching('ideas')
/* #endregion */

/* #region Idea Details */

/**
 * @param {object} config
 */
export const getIdeaDetailsUrl = ({ config }) =>
  config && config.payload._links ? config.payload._links.ideaDetails.href : ''

/**
 * @param {string} id
 */
export const getIdeaDetailsById = getItemDetailsById('idea-details')
export const fetchIdeaDetailsIfNeeded = fetchItemIfNeeded('idea-details')

/**
 * @param {object} config
 */
export const getCreateIdeaUrl = getUrlFromConfig('createIdea')

/* #endregion */

/* #region Pages */

export const getPageUrl = (key) => getUrlFromConfig(`sitemap-${key}`)
export const getPageDetails = getItemDetailsById('page-details')
export const fetchPageDetailsIfNeeded = fetchItemIfNeeded('page-details')
export const getIsPageFetching = (key) => false

/* #endregion */

/* #region Comments */

export const getCommentsListUrl = getUrlFromConfig('comments')
export const fetchCommentsListIfNeeded = fetchFilterablesIfNeeded('comments')
export const getCommentsList = getFilterablesListByKey('comments')
export const getIsCommentsFetching = getIsFetching('comments')

/* #endregion */

/* #region Forms */

export const getFormUrl = (key) => getUrlFromConfig(`forms-${key}`) // forms-search
export const getFormDetails = getItemDetailsById('forms')
export const fetchFormIfNeeded = fetchItemIfNeeded('forms')
export const getIsFormFetching = (key) => false

/* #endregion */

/* #region Languages */

export const getLanguages = ({ config }) => {
  try {
    return config.payload.languages.map((item) => item.toLowerCase())
  } catch (err) {
    return []
  }
}

/* #endregion */

/* #region Notifications */

export const getFetchNotificationsIfNeeded = ({ notifications }) => {
  return !notifications.payload.items
}

export const getUserNotificationsUrl = getUrlFromConfig('notifications')

export const getNotifications = ({ notifications }) => {
  try {
    return Object.values(notifications.payload.items)
  } catch (error) {
    return []
  }
}

export const getIsFetchingNotifications = ({ notifications }) =>
  notifications.isFetching

/* #endregion */

/* #region Events */

export const getFetchEventsIfNeeded = ({ config }) => !config.payload.language
export const getEventsState = ({ events }) => events
export const getEventTypes = ({ config }) => {
  return config.payload.eventTypes || []
}

/* #endregion */

/* #region Contacts */

export const getFetchContactsIfNeeded = ({ config }) => !config.payload.language
export const getContactsState = ({ contacts }) => contacts

export const getFetchPeopleGroupsIfNeeded = ({ config }) => !config.payload.language
export const getPeopleGroupsState = (state) => state.peopleGroups?.payload || []

export const getFetchInfoBoxesIfNeeded = ({ config }) => !config.payload.language
export const getInfoBoxesState = ({ infoboxes }) => infoboxes
/* #endregion */

/* #region OverviewBoxes */
export const getOverviewBoxesUrl = getUrlFromConfig('overviewBoxes')
export const fetchOverviewBoxesIfNeeded = fetchFilterablesIfNeeded('overview-boxes')
export const getOverviewBoxesList = getFilterablesListByKey('overview-boxes')
/* #endregion */

/* #region Intranet News */
export const getIntranetNewsListUrl = getUrlFromConfig('intranet-news')
export const getIntranetNewsState = ({ intranetNews }) => intranetNews

export const getFetchIntranetNewsIfNeeded = ({ config }) => !config.payload.language
/* #endregion */
